.back-button {
    display: flex;
    justify-content: space-around;
    gap: 9px;
    align-items: center;
    border-radius: 9px;
    padding: 10px 9px;
    width: 72px;
    height: 41px;
    background-color: #071251;
    border: 1px solid transparent;
    color: #ffffff;
}
