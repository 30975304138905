.search-here {
    display: flex;
    align-items: center;
    background-color: #071251;
    border: 1px solid #131e5a;
    border-radius: 5px;
    width: 274px;
    height: 43px;
    padding: 12px;

    input {
        flex-grow: 1;
        padding: 10px;
        background-color: #071251;
        border: none;
        color: #ffffff;
        font-weight: 400;
        font-size: 14px;
        border-radius: 0;

        &::placeholder {
            color: #ffffff;
            opacity: 0.7;
        }
    }

    button {
        background-color: #071251;
        border: none;
        padding: 10px;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: #ffffff;
            width: 16px;
            height: 16px;
        }
    }
}

.search-here .search-input {
    border: none;
    outline: none;
}

.search-here .search-input:focus {
    border: none; 
}