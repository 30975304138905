$side-padding: 17px;
$vertical-padding: 10px;

.gallery-cards {
    width: 433px;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 19px;
    align-items: flex-end;
}

.gallery-item {
    width: 90px;
    height: 110px;

    img{
        cursor: pointer;
        width: 90px;
        height: 110px;
        object-fit: cover;
    }
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 19px;
}

.gallery-item {
    margin-bottom: 10px;

    &:before,
    &:after {
        content: "";
        display: block;
        width: $side-padding;
    }

    &:after {
        margin-left: -$side-padding; // Відступ праворуч
    }
}
