.filters {
    width: 144px;
    height: 584px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #131e5a;
    background-color: #071251;
    color: #ffffff;
    margin-top: 13px;
}

.filters h2 {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin-bottom: 14px;
    line-height: 1.4;
    font-weight: 400;
}

.filter-range,
.filter-category {
    margin-bottom: 20px;
}

.filter-range h3,
.filter-category h3 {
    margin-bottom: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    color: #ffffff;
}

input::placeholder {
    text-align: left;
    padding: 0;
    margin: 0;
    color: #6e6e6e;
    font-size: 14px;
    font-weight: 400;
}

.input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.input-group label {
    margin-bottom: 5px;
    color: #ffffff;
}

.input-group input {
    padding: 0px 3px 0;
    background-color: #ffffff;
    border: 1px solid #131e5a;
    border-radius: 5px;
    color: #ffffff;
}

.input-box{
    display: flex;
    gap: 10px;
}

.input-group input::placeholder {
    font-size: 14px;
    color: #6e6e6e;
    font-weight: 400;
}

.button-option {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 14px;
    font-weight: 400;
}

.item-list {
    margin-left: 25px;
}