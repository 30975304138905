.gallery-details {
    padding: 16px 14px;
    margin-top: 21px;
    margin-left: 43px;
    margin-right: 53px;
    width: 472px;
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.gallery-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: black;
    margin-top: 8px;
}

.gallery-title-h3 {
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: black;
    margin-top: 4px;
    align-items: center;
    gap: 4px;
}
.input_default {
    border: 2px solid #4CAF50;
    border-radius: 8px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
