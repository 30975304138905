.gallery-cards{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  img{
    width: 90px;
    height: 110px;
    object-fit: cover;
    cursor: pointer;
  }
}
.puk{
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.nft_list_item{
  display: flex;
  gap: 10px;
  align-items: center;
}
.nft_image{
  width:30px;
  height: 30px;
  object-fit: cover;
  cursor: pointer;
}
.galleryBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  input{
    border: none;
    min-width: 50px;
  }
}
.galleryBox__input-block{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.galleryBox__name, .galleryBox__description{
  display: flex;
  gap: 10px;
  align-items: center;
}
.custom-file-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-button {
  align-self: flex-start;
  padding: 12px 24px;
  background-color: #0A1547;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.upload-button:hover {
  background-color: #081236;
}

.file-name {
  margin-top: 10px;
  font-size: 14px;
  color: black;
}

.galleryButtonCreate{
  align-self: flex-start;
  height: 35px;
  padding-right: 13px;
  padding-left: 13px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #28c76f;
  font-weight: 500;
  margin-left: 25px;
  cursor: pointer;
  color: #28c76f;
}