.table__container{
    width: 100%;
    padding: 30px;
    .table__title{
        padding-bottom: 30px;
        font-weight: 500;
        font-size: 18px;
        color: #34495e;
    }
}
.table__row{
    color: #5b6e88;
    display: flex;
    width: 100%;
}
.status{
    width: 45%;
    margin: 0 auto;
    .status__false{
        width: 100%;
        height: 100%;
        border: #FF9F43 1px solid;
         color: #FF9F43;
         background-color:   rgba(255, 159, 67, 0.1);
         border-radius: 999px;
    }
    .status__true{
        border: #28c76f 1px solid;
        color: #28c76f;
        border-radius: 999px;
        width: 100%;
        background-color:  rgba(40, 199, 111, 0.1);
    }
}
.featured{
    
    margin: 0 auto;
    width: 40%;
    
    
    
    .featured__false{
        width: 100%;
        height: 100%;
        border: #FF9F43 1px solid;
         color: #FF9F43;
         background-color:   rgba(255, 159, 67, 0.1);
         border-radius: 999px;
    }
    .featured__true{
        border: #28c76f 1px solid;
        color: #28c76f;
        border-radius: 999px;
        width: 100%;
        background-color:  rgba(40, 199, 111, 0.1);
    }
}
.table_btn{
    text-align: center;
    font-size: 14px;
    color: #4634FF;
    border-radius: 3px;
    width: 65px;
    border:#4634FF 1px solid ;
}
.table_btn_more{
    text-align: center;
    font-size: 14px;
    color: #1E9FF2;
    border-radius: 3px;
    width: 65px;
    border:#1E9FF2 1px solid ;
}
.table_buttons{
    display: flex;
    gap: 10px;
    justify-content: end;
}
.table__title{
    display: flex;
    justify-content: space-between;
    .table__title-buttons{
        display: flex;
        gap: 10px;
        .search{
            display: flex;
            align-items: center;
            width: 228px;
            height: 45px;
            color:  #c9c9c9;
            background-color: rgba(255,255,255,0.05);
            border-radius: 5px;
            border: rgba(255, 255, 255, 0.15) 1px solid ;
            span{
                background-color: #4634FF;
                width: 45px;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0 5px 5px 0;
            }
            input{
                border: solid #CED4DA 1px;
               background-color: transparent;
                outline: none;
                border-radius: 5px 0 0 5px;
                height: 45px;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                &::placeholder{
                    // color:  #c9c9c9;;
                }
                
            }
        }
    }
}
.open-modal-form{
    border: #4634FF 1px solid;
    font-weight: 400;
    font-size: 14px;
    line-height: 250%;
    color: #4634FF;
    border-radius: 3px;
    width: 99px;
    background-color: transparent;
    height: 45px;
}