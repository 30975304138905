

.gallary {
    display: flex;
    background-color: #E0E0E0;
    width: 100%;
    overflow: hidden;
}

.gallery__container {
    flex: 5;
    width: 100%;
    
}

.gallary-box {
    display: flex;
    gap:43px;
}