.gallery-main {
    padding: 11px;
    background-color: #fff;
    width: 587px;
    margin-top: 21px;
    margin-left: 30px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.gallary-nav {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.gallery-filtrs-cards{
    display: flex;
    align-items: flex-start;
    gap: 7px;
}

.gallary-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4;
    color: black;
}