

.wrapper {
    position: relative;
    margin: 0 auto;
    width: 526px;
    background-color: #1e157d;
    color: white;
    border-radius: 10px;
    z-index: 3;
}
.log-background{
    background-image: url('../../Assets/images/login.jpg');
    background-size: cover; /* Зображення розтягується і покриває весь контейнер */
    background-position: center; /* Центрування зображення */
    background-repeat: no-repeat; /* Вимкнення повтору зображення */
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
}
.log-background img{
    position: absolute;
    top: 0;
    left: 0;
}
.log-background:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.2);
    z-index: 2;
  }
.form-title {
    position: relative;
    text-align: center;
    font-weight: 600;
    padding: 60px 30px 40px 30px;
    background-color: #3D2BFB;
    border-radius: 10px;
}
.form-title::after {
    position: absolute;
    content: '';
    bottom: -24px;
    left: 5px;
    right: 5px;
    z-index: -1;
    border-width: 25px 256px 0px 256px;
    border-style: solid;
    border-color: #3d2bfb transparent transparent transparent;
}
.form-input{
    padding: 60px 40px 40px 40px;;
}
.form-title h2{
    font-size: 30px;
    font-weight: 600;
}
.wrapper span{
    text-transform: uppercase;
    font-weight: bolder;
}
.form-title p {
    font-size: 17px;
}
.wrapper .input-box {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;
    margin: 15px 0;
    
}
.input-box label{
    margin-bottom: 5px;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
}
.input-box span{
    color: #DC3545;
}
.input-box input {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    height: 50px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    padding: 20px 45px 20px 20px;
    color: white;
}
.wrapper .remember-forgot{
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
}
.remember-forgot input[type=checkbox]{
    display: none;
}
.remember-forgot label {
   display: flex;
   align-items: center;
}
.remember-forgot input[type=checkbox] + label:before {
   
    content: '';
    font-family: 'Line Awesome Free';
    font-weight: 900;
    width: 15px;
    margin-right: 15px;
    height: 15px;
    border: 1px solid #fff;
    border-radius: 3px;
    color: #fff;
    display: inline-block;
    text-align: center;
    align-items: center;
    font-size: 11px;
    transition: all 0.3s;
}

input[type=checkbox]:checked + label:before {
    content: '\2713';
    text-align: center;
    font-size:14px;
    color:white;
    background-color: #0d6efd;
    border-color: #0d6efd;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remember-forgot a{
    color: white;
}
.wrapper .btn {
    cursor: pointer;
    width: 100%;
    height: 50px;
    background-color: #3d2bfb;
    color: white;
    border: none;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    border-radius: 4px;
    margin-top: 40px;
}