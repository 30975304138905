.gallary-box-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #d9d9d9;
    margin-bottom: 4px;
    padding: 13px 4px 12px 9px;
}

.gallery-list {
    margin-top: 36px;
}

.list-title {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #5b6e88;
}

.create-button {
    border-radius: 15px;
    padding: 7px 17px;
    width: 110px;
    height: 41px;
    background-color: #071251;
    border: 1px solid transparent;
    color: #ffffff;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.5;
}

.box-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.edit-input {
    background: transparent; 
    border: none; 
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #5b6e88;
    width: 100%;
    outline: none;
}

.edit-input:focus {
    outline: none; 
}

.edit-button {
    display: flex;
    padding: 5px 9px 5px 10px;
    align-items: center;
    gap: 3px;
    border: 1px solid #4634ff;
    border-radius: 3px;
    width: 63px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #4634ff;
}

.more-button {
    display: flex;
    padding: 5px 9px 5px 10px;
    align-items: center;
    gap: 3px;
    border: 1px solid #1e9ff2;
    border-radius: 3px;
    width: 70px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #1E9FF2;
}