
.modal-window{
    height: 100vh;
    width: 100vw;
    background-color: rgba(0,0,0,0.4);
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    pointer-events: none;
}
.modal-window.active{
    opacity: 1;
    pointer-events: all;
}
.wrapper-form {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 805px;
height: 600px;
    padding: 16px;
    transform: scale(0.5);
    transition: 0.4s all;
    font-weight: 400;
font-size: 32px;
line-height: 66%;
    .modal-window__form {
        position: relative;
        display: flex;
        flex-direction: column;
        
        width: 100%;
        height: 600px;
        max-width: 805px;
        padding: 20px 20px;
        background-color: #ededed;
        border: 2px dashed rgb(186, 191, 195);
        border-radius: 10px;
        box-shadow: 0 0 4px 4px rgba(255, 255, 255, 0.5);
        transition: border-color 0.3s, background-color 0.3s;
        h3{
            width: 100%;
            font-weight: 400;
            font-size: 32px;
            line-height: 66%;
            border-bottom: 1px solid black;
            padding-bottom: 10px;
        }
        .form-block{
            display: flex;
            height: 100%;
            justify-content: center;
            gap: 20px;
            
            .form-input__image{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
            span:last-of-type{
                font-weight: 400;
                font-size: 20px;
                line-height: 105%;
                span{
                    font-weight: 700;
                }
            }
            p{
                border-radius: 37px;
                width: 346px;
                height: 240px;
                background: #d9d9d9;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
      }
      .form-input__image{
        display: flex;
        flex-direction: column;
           img{
             width: 346px;
             height: 240px;
             object-fit: cover;
             border-radius: 20px;
           }
      }
      
  }
  .wrapper-form.active{
    transform: scale(1);
  }
  .form-input__text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
  }
  .form-input__name{
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    input{
        border-radius: 13px;
        width: 367px;
        height: 63px;
        padding: 20px;
    }
  }
  .file-list{
    font-size: 15px;
    text-wrap: wrap;
    width: 100%;
    border: 1px #4335f5 solid;
    margin-top: 5px;
    text-align: center;
    list-style: none;

  }
  .form-input__description{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding-bottom: 40px;
    gap: 20px;

    input{
      vertical-align: top;
      padding: 20px;
        border-radius: 13px;
        width: 367px;
        height: 219px;
    }
    textarea{
      vertical-align: top;
      padding: 20px;
      border-radius: 13px;
      width: 100%;
      height: 250px;
      resize: none;
    }
  }
  .modal-window__form.drag {
    border-color: rgb(62, 201, 80);
    background-color: #e6ffe3;
  }
  
  .label__image {
    padding: 5px 10px;
    background-color:  #61c478;;
    border-radius: 18px;
    font-weight: 400;
    font-size: 32px;
    line-height: 66%;
    text-align: center;
    color: #fff;
    cursor: pointer;
  }
  .modal-window__input-file {
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  .modal-form-btn{
    font-weight: 400;
font-size: 16px;
line-height: 66%;
text-align: center;
color: #fff;
border-radius: 20px;
width: 716px;
height: 59px;
background: #4335f5;
border: none;
  }